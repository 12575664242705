.second-section {
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  .second-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('./assets/bg.jpg') no-repeat center center;
    background-size: cover;
    opacity: 0.5; 
    z-index: 0;
  }
  
  .scrolling-banner {
    margin-top: 0;
    margin-bottom: 100px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #f880ae;
    padding: 20px 0;
    position: relative;
    z-index: 1;
  }
  
  .scrolling-text {
    display: inline-block;
    padding-left: 100%;
    animation: scroll 100s linear infinite;
    font-size: 2em;
    color: #000;
  }

  .second-section:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, #f880ae, rgba(1, 179, 253, 0));
    z-index: 1;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .section-title {
    text-align: center;
    font-size: 5em;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin: 20px 0;
    position: relative;
    z-index: 1;
  }
  
  .family-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 50px 20px;
    position: relative;
    z-index: 1;
    height: 500px;
  }
  
  .family-member {
    flex: 1 1 200px;
    margin: 10px;
    border-radius: 15px;
    text-align: center;
    font-size: 1.5em;
    color: #000;
    position: relative;
    overflow: hidden;
    z-index: 1;
  }
  
  .family-image-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .family-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }
  
  .family-member-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  
  .overlay-text {
    color: white;
    font-size: 0.9em;
    text-align: center;
  }
  
  .family-member:hover .family-image {
    transform: scale(1.1);
  }
  
  .family-member:hover .family-member-overlay {
    opacity: 1;
  }
  
  .scrolling-name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #FFA500;
    overflow: hidden;
    white-space: nowrap;
  }
  
  
  @keyframes scroll-name {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  