@font-face {
    font-family: 'KidPlay';
    src: url('./fonts/kidplay.otf') format('opentype');
  }
  
  body {
    margin: 0;
    font-family: 'KidPlay', sans-serif;
    background-color: #f71b44;
  }
  
  .main-container {
    position: relative;
    height: 100vh;
    width: 100vw;
  }
  
  .main-container:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    background: linear-gradient(to top, #f880ae, rgba(1, 179, 253, 0));
    z-index: 1;
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 80px;
    background-color: #f71b44;
  }
  
  .nav-links {
    display: flex;
  }
  
  .nav-link {
    color: white;
    text-decoration: none;
    margin: 0 15px;
    font-size: 1.2em;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  
  .top-right-icons {
    display: flex;
    align-items: center;
  }
  
  .icon {
    width: 24px;
    height: 24px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .buy-now-button {
    background-color: #f7649c;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: white;
    margin-left: 10px;
    cursor: pointer;
    font-family: 'KidPlay', sans-serif;
  }
  
  .buy-now-button:hover {
    background-color: #f7649c;
  }
  
  /* Burger menu */
  .burger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .burger-bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 4px 0;
  }
  
  /* Mobile nav links */
  .mobile-nav-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f880ae;
    padding: 20px 0;
  }
  
  .content-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 80px;
  }
  
  .text-container, .image-container {
    flex: 1;
    padding: 20px;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px;
  }
  
  .text-container h1 {
    font-size: 8em; 
    color: white;
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000; 
    margin-top: 0px;
    margin-bottom: 0px; 
  }
  
  .text-container p {
    font-size: 3em;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    margin-top: 0; 
    margin-bottom: 20px; 
  }
  
  .button-container {
    display: flex;
    gap: 10px;
  }
  
  .action-button {
    background-color: #f7649c;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: white;
    cursor: pointer;
    font-family: 'KidPlay', sans-serif;
  }
  
  .action-button:hover {
    background-color: #f7649c;
  }
  
  .bear-image {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 1390px) {
    .nav-links {
      display: none;
    }
  
    .navbar {
      padding: 40px 20px;
    }
  
    .burger-menu {
      display: flex;
      position: absolute;
      left: 20px;
    }
  
    .top-right-icons {
      margin-left: auto;
    }
  
    .nav-link {
      margin-bottom: 10px;
    }
  
    .bear-image {
      width: 100%;
      max-width: 400px;
    }

  
    .content-container {
      flex-direction: column;
    }
  
    .text-container, .image-container {
      flex: none;
    }
  
    .text-container h1 {
      font-size: 4em;
    }
  
    .text-container p {
      font-size: 1.5em;
    }
  
    .content-container {
      padding: 20px 30px;
    }
  
    .button-container {
      gap: 10px;
    }

    .main-container:after {
        height: 0px;
      }
  }
  