.third-section {
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #f880ae;
}

.third-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./assets/bg1.jpg') no-repeat center center;
  background-size: 90%;
  opacity: 0.5; 
  z-index: 0;
}


.third-section:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background: linear-gradient(to top, #f880ae, rgba(1, 179, 253, 0));
  z-index: 1;
}
  
  .section-title {
    text-align: center;
    font-size: 4em;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    margin: 20px 0;
  }
  
  .content-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
  }
  
  .text-container, .image-container {
    flex: 1 1 45%;
    min-width: 300px;
    margin: 20px;
  }
  
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .text-block {
    margin: 10px 0;
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-size: 0.6em;
  }
  
  .tree-image {
    max-width: 80%;
    height: auto;
  }
  

  .scrolling-banner1 {
    margin-top: 0;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #FFA500;
    padding: 20px 0;
    position: relative;
    z-index: 1;
  }