.fourth-section {
    background-color: #f880ae;
    padding: 50px 20px;
  }
  
  .section-title {
    text-align: center;
    font-size: 4em;
    color: black;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;  
    margin: 20px 0;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    gap: 20px;
  }
  
  .card {
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    flex: 1 1 45%;
    max-width: 45%;
    min-width: 300px;
    margin: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .card-number {
    background-color: red;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card-content {
    margin-top: 30px;
    text-align: center;
  }
  
  .card-content h3 {
    margin: 10px 0;
    font-size: 2em;
    color: black;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;  
  }
  
  .card-content p {
    font-size: 1.5em;
    color: black;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;  
  }
  
  @media (max-width: 768px) {
    .card-container {
      flex-direction: column;
      align-items: center;
    }
  
    .card {
      max-width: 90%;
      min-width: 90%;
    }
  }
  