.footer {
    background-color: #f880ae;
    color: white;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    border-top: 2px solid white;
  }
  
  .footer-title {
    font-size: 4em;
    margin: 0;
  }
  
  .footer-subtitle {
    font-size: 1.5em;
    margin: 10px 0 30px 0;
  }
  
  .footer-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .footer-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1.2em;
    cursor: pointer;
  }
  
  .buy-button {
    background-color: #f71b44;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: white;
    margin-left: 10px;
    cursor: pointer;
    font-family: 'KidPlay', sans-serif;
  }
  
  .dex-button {
    background-color: #f71b44;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; 
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    font-size: 1.2em;
    color: white;
    margin-left: 10px;
    cursor: pointer;
    font-family: 'KidPlay', sans-serif;
  }

  .dex-button:hover {
    background-color: #f71b44;
  }

  .buy-button:hover {
    background-color: #f71b44;
  }
  
  
  .footer-email {
    font-size: 1em;
    margin-bottom: 20px;
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .social-icon {
    width: 40px;
    height: 40px;
  }
  
  .background-image {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.2; 
  }
  
  .left-goose {
    left: 10px;
    width: 350px; 
  }
  
  .right-goose {
    right: 10px;
    width: 350px;
  }
  
  @media (max-width: 768px) {
    .footer-title {
      font-size: 3em;
    }
  
    .footer-subtitle {
      font-size: 1.2em;
    }
  
    .footer-buttons {
      flex-direction: column;
      gap: 10px;
    }
  
    .footer-button {
      font-size: 1em;
    }
  
    .footer-email {
      font-size: 0.9em;
    }
  
    .social-icon {
      width: 30px;
      height: 30px;
    }
  
    .left-goose,
    .right-goose {
      width: 150px; 
      top: 60%; 
    }
  }
  
  @media (max-width: 480px) {
    .footer-title {
      font-size: 2.5em;
    }
  
    .footer-subtitle {
      font-size: 1em;
    }
  
    .footer-buttons {
      flex-direction: column;
      gap: 5px;
    }
  
    .footer-button {
      font-size: 0.9em;
    }
  
    .footer-email {
      font-size: 0.8em;
    }
  
    .social-icon {
      width: 25px;
      height: 25px;
    }
  
    .left-goose,
    .right-goose {
      width: 120px;
      top: 70%; 
    }
  }
  